import { Fragment, React } from "react";
import { Grid } from "@material-ui/core";
import FB from "../Img/icono FB.png";
import INS from "../Img/icono INS.png"


const RoadsideAssistance = () => {
    return (
        <Fragment>
            <section id="roadsideassistance">
                <Grid container >
                    <Grid item xs={12} md={12} lg={12} style={{paddingBottom: 50, paddingTop: 50, textAlign: 'center' }}>
                    <div >
                        <p style={{ color: 'white' }}>235 Satellite Blvd. Bldg. 400, Ste. 300 Duluth, GA 30096</p>
                       
                        <p style={{ color: 'white' }}>copyright© 2020 Grey Stone Insurance Agency - all rights reserved</p>
                        <a href = "https://www.facebook.com/gstoneinsurance/"><img src={FB} style={{width: '50px', height: '50px' }}/></a>
                        <a href = "https://www.instagram.com/greystoneinsuranceagency/"><img src={INS} style={{width: '50px', height: '50px' }}/></a>
                    </div>
                    </Grid>    
                </Grid>
            </section>
        </Fragment>
    );
}

export default RoadsideAssistance;