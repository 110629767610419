import React, { Fragment} from "react";
import { Grid, Card, CardContent, Button, AppBar, ListItemText, ListItem, Divider, List, Dialog, Toolbar, IconButton, Slide,DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import ad2 from "../Img/ad2.jpg";
import ad6 from "../Img/ad6.jpg";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Flyers = () => {
    const[open, setOpen] = React.useState(false);
    const[open1, setOpen1] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen1 = () => {
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    return (
        <Fragment>
            <section id="flyers">
                <h2>Flyers</h2>
                <Grid container >
                    <Grid item xs={12} md={12} lg={12} style={{paddingBottom: 50, paddingTop: 50 }}>
                        
                    <div >
                       <img 
                            src={ad2} 
                            style={{width: '760px', height: '1000px'}}
                            onClick={handleClickOpen}/>
                          &emsp;
                       <img src={ad6} style={{width: '770px', height: '1000px'}} onClick={handleClickOpen1}/>
                    </div>
                    <div>
                <Dialog  maxWidth={maxWidth} open={open} onClose={handleClose}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <img src={ad2} style={{width: '750px', height: '1000px'}}/>
                            </DialogContentText>
                        </DialogContent>
                </Dialog>
            </div>
            <div>
                <Dialog  maxWidth={maxWidth} open={open1} onClose={handleClose1}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <img src={ad6} style={{width: '750px', height: '1000px'}}/>
                            </DialogContentText>
                        </DialogContent>
                </Dialog>
            </div>
                    </Grid>    
                </Grid>
            </section>
        </Fragment>
    );
}

export default Flyers;