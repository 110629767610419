import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import about1 from "../Img/green.png";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const About = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <section id="about">
                <h2>About Us</h2>
                <Grid container direction="row-reverse">
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>

                        <Card className={classes.root}>
                            <CardContent>
                                <FadeInSection>
                                    <Typography component="p">
                                       <h2> <b>"GREY STONE working hand to hand on the steering wheel of your business"</b></h2>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <p>
                                        In 2021 Grey Stone Insurance Agency was formed by a group of insurance industry executives each with over 20 years experience and various insurance programs.
                                        </p>
                                        <p>
                                        Our executives along with their group of experienced staff and associates are committed to help provide the markets and insurance coverages you need and desire.
                                        </p>
                                        <p>
                                        At Grey Stone Insurance Agency we offer Products and Programs that fit on your trucking business and we are committed to build a long-term business relationship with you.
                                        </p>
                                        <p>
                                        Our team is driven to help all our clients to succeed. Using our cutting edge systems technology, our team will always be available to help you.
                                        </p>
                                    </Typography>
                                </FadeInSection>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                        <Card>
                        <CardMedia className={classes.media} image={about1}/>
                        </Card>
                    </Grid>

                </Grid>


            </section>
        </Fragment>
    );
}

export default About;