import { Collapse, Fab, fade, Grid, List, ListItem, Button, makeStyles, useMediaQuery } from "@material-ui/core";
import { Fragment, React, useState } from "react";
import background from "../Img/cover.jpg"
import logo from "../Img/STONEXG.png";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FadeIn from "react-fade-in";


const useStyles = makeStyles((theme) => ({
    
    bannerStyle: 
    {
        backgroundImage: `url(${background})`,
        backgroundColor: 'white',
        backgroundRepeat: 'round',
        backgroundSize: 'cover'
    },  
    nested: {
        padding: '0px 0px 0px 0px !important',
    },
    extendedIcon: {
        marginRight: theme.spacing(3),
    },
    buttonQuote: {
        marginTop: '50px',
        backgroundColor: '#EE4C3E',
        fontWeight: 'bold',
        color: 'white !important'
    },
    buttonsBap: {
        marginTop: '50px',
        backgroundColor: '#EE4C3E',
        fontWeight: 'bold',
        marginRight: '50px',
        color: 'white !important'
    }
}));
const NavBar = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:770px)');
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <FadeIn>
            <header id="home" className={classes.bannerStyle}>
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>
                    <FadeIn>
                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home" style={{ color: '#1F2D4F' }}>HOME</a></li>
                        {/*<li>
                            <a className="smoothscroll" href="#coverages" onMouseEnter={handleClick}>PRODUCT LINES {open ? <ExpandLess /> : <ExpandMore />}</a>

                        </li>
                        <li><a className="smoothscroll" href="#markets">MARKETS</a></li>*/}
                        <li><a className="smoothscroll" href="#about">ABOUT US</a></li>
                        {/*<li><a className="smoothscroll" href="#getquote">GET QUOTE</a></li>
                        <li><a className="smoothscroll" href="#becomeproducer">BECOME PRODUCER</a></li>*/}
                        <li><a className="smoothscroll" href="#contact">CONTACT US</a></li>
                        {/*li><a className="smoothscroll" href="#claims">CLAIMS</a></li>
                        <li><a className="smoothscroll" href="#officeofcompliance">COMPLIANCE</a></li>
                        <li><a className="smoothscroll" href="#careers">CAREERS</a></li>
                        <li><a className="smoothscroll" href="#preferedvendors">Vendors</a></li>*
                        <li><a className="smoothscroll" href="#resources">RESOURCES</a></li>*/}
                    </ul>
                    </FadeIn>
                    {/*<Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#132d5a', color: 'white' }}>
                        <List component="div" disablePadding onMouseLeave={handleClick}>
                            <ListItem className={classes.nested}>
                                Commercial Auto liability
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Collision coverage / Physical Damage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Uninsured and underinsured motorist coverage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Comprehensive / Physical Damage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Motor Truck Cargo (MTC)
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Trailer Interchange
                            </ListItem>
                        </List>
                    </Collapse>*/}
                </nav>

                {
                    !width ?
                    
                        <Grid container style={{ float: 'c' }}>

                            <Grid item xs={12} lg={6} md={6} >
                                <div className="banner-text">
                                    <img alt="company logo" src={logo} style={{width: '650px', height: '200px'}} />
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={6} md={6}>
                                {/*<Fab
                                    variant="extended"
                                    size="medium"
                                    color="primary"
                                    aria-label="add"
                                    className={classes.buttonsBap}
                                    href="#becomeproducer"

                                >
                                    <AssignmentIcon style={{ color: 'white' }} className={classes.extendedIcon} />
          Get Appointed
        </Fab>
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    color="primary"
                                    aria-label="add"
                                    className={classes.buttonQuote}
                                    href="#getquote"
                                >
                                    <DescriptionIcon className={classes.extendedIcon} />
          Get a Quote
                                </Fab>*/}
                                
                                <p style={{ color: 'white', font: '10px', marginTop: '0%', textAlign: "center", lineHeight: '50px', padding: '50px' }}>
                                <FadeIn>
                                Grey Stone Insurance Agency, strives to exceed customer expectations with superior service, quality insurance products and tailoring program options to fit all your client’s needs.<br />
                                </FadeIn>
                                </p>
                                
                            </Grid>
                        </Grid>
                        :

                        <Grid container style={{ float: 'left', marginTop: '100px' }}>
                            <Grid item xs={false} sm={6} lg={6} md={6}>
                            <div /*className="banner-text"*/>
                                    <img alt="company logo" src={logo} style={{width: '520px', height: '250px'}}/>
                                </div>
                                {/*<div style={{ textAlign: 'center' }}>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonsBap}
                                        href="#becomeproducer"
                                    >
                                        <AssignmentIcon className={classes.extendedIcon} />
          Get Appointed
        </Fab>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonQuote}
                                        href="#getquote"

                                    >
                                        <DescriptionIcon className={classes.extendedIcon} />
          Get a Quote
                            </Fab>
                            </div>*/}
                         
                                <p style={{ color: 'white', font: '30px', marginTop: '20%', textAlign: "justify", lineHeight: '35px', paddingLeft: '230px', width: '700px' }}>
                                <FadeIn>
                                Grey Stone Insurance Agency, strives to exceed customer expectations with excellent service and quality in the insurance products we offer.
                                <b>Grey Stone working hand to hand on the steering wheel of your business.</b>
                                </FadeIn>
                                </p>
                            </Grid>
                        </Grid>
                        
                }


                {/*<p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
            </p>*/}
            </header>
            </FadeIn>
        </Fragment>
    );
}

export default NavBar;