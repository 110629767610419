import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './Components/Index/Main';
import Ontrak from './Components/Ontrak';
import MessengerCustomerChat from 'react-messenger-customer-chat';


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/ontrak" component={Ontrak} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
      {/*<MessengerCustomerChat pageId="112533487869174" appId="404089448057924" />,*/}
    </div >
  );
}
 
export default App;
