import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { Fragment, React } from "react";
import contact from "../Img/contact.png";
import inbox from "../Img/inbox.gif";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    gridRowImage: {
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const Contact = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="contact" >
                <h2>Contact Us</h2>
                <Grid container direction="row-reverse" >
                    <Grid item xs={12} md={4} lg={4} style={{ paddingTop: 50, textAlign: 'left' }}>
                        <div>
                        <h1 style={{ color: 'black' }}>Office Hours</h1>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Monday thru Friday from 8:00 AM - 5:00 PM (PST)</p>
                            <br></br>
                            <h1 style={{ color: 'black' }}>Extended Office Hours</h1>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Saturday from 8:00 AM - 1:00 PM (PST) </p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Sunday from 9:00 AM - 1:00 PM (PST)</p>
                            <br></br>
                            <h1 style={{ color: 'black' }}>Telephone Number</h1>
                            <p style={{color: 'black', margin: '0px 0px 10px'}}><a href="tel:(208) 579-2000"><h1 style={{ color: 'blue', fontSize: '30px' }}>(208) 579-2000</h1></a></p>
                        </div>

                    </Grid>
                    
                    <Grid item xs={12} md={4} lg={4} style={{ padding: 60 }}>
                    <h1 style={{ color: 'black' }}>To send an email, please click below</h1>
                        <Grid container justify="center" style={{ paddingTop: '30px' }}>
                            <a href="https://www.greystoneinsuranceagency.com/Contact.html"><img src={inbox} width="100%"/></a>
                            {/*<Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>
                            <Grid item xs={3}>
                                Your Name
                            </Grid>
                            <Grid item xs={5} >
                                <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Telephone
                            </Grid>
                            <Grid item xs={5}>
                                <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                E-mail
                            </Grid>
                            <Grid item xs={5}>
                                <TextField size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Message
                            </Grid>
                            <Grid item xs={5}>
                                <TextField multiline rows={4} fullWidth variant="outlined" style={{ backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={7} style={{ padding: '25px', textAlign: 'end' }}>
                                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Contact  <a href="mailto:contact@sternib.com">(contact@sternib.com)</a><br /> (706) 395-4200</p>
                            </Grid>
                            <Grid item xs={5} style={{ paddingTop: '20px', textAlign: 'end' }}>
                                <Button variant="contained" color="primary">
                                    Reset
                                </Button>
                                <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}>
                                    Send
                                </Button>
                            </Grid>*/}
                        </Grid>
                         </Grid>
                    <Grid item xs={12} md={4} lg={4} className={classes.gridRowImage}>

                        <img alt="customer service agent" src={contact} width="100%"></img>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default Contact;