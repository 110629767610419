import { Fragment } from "react";
import About from "../About";
import BecomeProducer from "../BecomeProducer";
import Careers from "../Careers";
import Claims from "../Claims";
import Contact from "../Contact";
import CarouselComponent from "../Helpers/CarouselComponent";
import Coverages from "../Coverages";
import GetQuote from "../GetQuote";
import Markets from "../Markets";
import NavBar from "../NavBar";
import OfficeOfCompliance from "../OfficeOfCompliance";
//import PreferedVendors from "../PreferedVendors";
import Resources from "../Resources";
import RoadsideAssistance from "../RoadsideAssistance";
import Flyers from "../Flyers";

const Main = () => {
    return (
        <Fragment>
            <NavBar />
           {/* <Coverages />
            <Markets />*/}
            <About />
            {/*<GetQuote />
            <BecomeProducer />*/}
            <Contact />
            <Flyers/>
            {/*<Claims />
            <OfficeOfCompliance />
            <Careers />
            <PreferedVendors />
            <Resources />*/}
            <RoadsideAssistance/>
        </Fragment>
    )
}
export default Main;